<template>
   <div class="comMain">
      <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="confirm"  :subtitle="title" v-if="!detail"></count-down>
      <div class="content">
        <div class="m-b-40 text-bold font-20">{{questionDetail.name}}</div>
        <div class="c-5d font-18">
          {{questionDetail.sub_name}}
        </div>
    </div>
    
    <!-- 小题板块 -->
    <div class="xiaoti" v-if="is_edit&&!detail">
      <block v-for="(item,index) in questionDetail.problem_list" :key="index" v-if="index<=problem_index">
        <div class="flex m-b-40" v-if="!item.is_choose">
          <img src="../assets/image/jiqi.png" alt="" class="jiqi">
          <div>
            <div class="font-13 c-8d">仔细听，你只能播放一次音频</div>
            <multi-audio :audioId="'a'+index" :audioData="item"></multi-audio>
          </div>
        </div>
        <div v-if="!item.is_choose">
          <div class="font-16 text-bold">Question{{index+1}} of {{questionDetail.problem_list.length}}</div>
          <div class="font-16 text-bold m-b-14">{{item.name}}</div>
          <div class="options" :class="m.checked?'active':''" v-for="(m,n) in item.options" :key="n" @click="chooseRadio(n)">
            <img src="../assets/image/radio2.png" alt="" v-if="m.checked">
            <img src="../assets/image/radio.png" alt="" v-else>
            <div class="flex-1">{{m.translate}} </div>
          </div>
        </div>
        <!-- 答题以后的答案 -->
        <div class="tibox" v-if="item.is_choose">
          <div class="tirow">
            <img src="../assets/image/jiqi.png" alt="" class="jiqi">
            <div class="txt">{{item.audio_name}}</div>
          </div>
          <!-- 正确 -->
          <block v-if="item.choose_answer == item.answer">
            <div class="tirow_r" >
              <img src="../assets/image/dui.png" alt="" class="dui">
              <div class="txt">{{item.answer_name}}</div>
            </div>
          </block>
           <!-- 错误 -->
          <block v-else>
            <div class="tirow_r" >
              <img src="../assets/image/dui.png" alt="" class="dui">
              <div class="txt">{{item.answer_name}}</div>
            </div>
            <div class="tirow_r err" >
                <img src="../assets/image/err.png" alt="" class="dui">
                <div class="txt">{{item.choose_answer_content}}</div>
            </div>
          </block>
        </div>
       
      </block>
    </div>
    <!-- 答题后 -->
    <div class="xiaoti" v-if="detail">
      <block v-for="(item,index) in questionDetail.problem_list" :key="index">
        <div class="flex m-b-40">
          <img src="../assets/image/jiqi.png" alt="" class="jiqi">
          <div>
            <div class="font-13">仔细听，你只能播放一次音频</div>
            <multi-audio :audioId="'a'+index" :audioData="item"></multi-audio>
          </div>
        </div>
        <div>
          <div class="font-16 text-bold">Question{{index+1}} of {{questionDetail.problem_list.length}}</div>
          <div class="font-16 text-bold m-b-30">{{item.name}}</div>
          <div class="options" :class="m.name==questionDetail.user_answer[index]?'active':''" v-for="(m,n) in item.options" :key="n" >
            <img src="../assets/image/radio2.png" alt="" v-if="m.name==questionDetail.user_answer[index]">
            <img src="../assets/image/radio.png" alt="" v-else>
            <div class="flex-1">{{m.translate}} </div>
          </div>
        </div>
       
      </block>
    </div>
   
   </div>
</template>
<script>
export default {
    props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
   data() {
      return {
        correct: false,   //答案
        answer: '',
        title: "开始答题", //下一小题   确认
        is_edit: false,   //是否编辑
        problem_index: 0,  //小题索引
        is_first: false, //是否第二次点击下一小题
      }
   },
   methods: {
    confirm() {
        this.is_edit = true;
        if(this.title == "确认") {
          this.nextSubmit()
        }
        if(parseInt(this.problem_index+1) == this.questionDetail.problem_list.length) {
          this.title = "确认";
        }else if(parseInt(this.problem_index+1) < this.questionDetail.problem_list.length){
          this.title = "下一小题";
          if(this.is_first) {
            //判断当前小题是否提交答案
            if(this.questionDetail.problem_list[this.problem_index].is_choose == false) {
              this.$message({
                message: "请选择答案",
                type: 'warning'
              });
              return
            }
          }
          if(this.is_first) this.problem_index++;
          this.is_first = true;
          if(parseInt(this.problem_index+1) == this.questionDetail.problem_list.length) this.title = "确认";
        }
      },
      chooseRadio(index) {
        this.questionDetail.problem_list[this.problem_index].is_choose = true;
        this.questionDetail.problem_list.forEach((v,k) => {
          if(k == this.problem_index) {
            v.options.forEach((m,n) => {
              if(index == n) {
                m.checked = true;
                // console.log("choose_answer",m.name)
                v.choose_answer = m.name;   //插入所选答案选项
                v.choose_answer_content = m.translate;   //插入所选答案选项值
              }
            })
          }
        })
        // console.log(this.questionDetail.problem_list)
        this.$forceUpdate()
      },
      nextSubmit() {
        //获取填写的答案
        var answer = [];
        this.questionDetail.problem_list.forEach((v,k) => {
            v.options.forEach((m,n) => {
              if(m.checked == true) {
                 answer.push(m.name);
              }
            })
        })
        
        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)

        this.$emit("submitAnswer",{
          answer: answer,
          supletime: supletime
        })
      },
   }
}
</script>
<style  lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    width: 700px;
    margin: 40px auto;
  }
  
  .xiaoti {
    padding: 20px;
    border-radius: 2px;
    width: 700px;
    margin: 30px auto;
    border: 1px solid #BBBEC9;
    .jiqi {
      width: 53px;
      height: 53px;
      margin-right: 20px;
    }
    .audio {
      display: flex;
      align-items: center;
      background-color: #F6F7FB;
      border-radius: 20px;
      padding: 20px 30px;
      img {
        width: 81px;
        height: 71px;
        margin-right: 10px;
      }
    }
  }
  .options {
    display: flex;
    align-items: center;
    background-color: #F6F7FB;
    border-radius: 20px;
    padding: 20px 30px;
    margin-bottom: 20px;
    box-sizing: border-box;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .active {
    color: $color-title2;
    border: 1px solid $color-title2;
    background-color: #fff!important;
    div {
      color: $color-title2;
    }
  }
  
  .tibox {
    .tirow {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .jiqi {
        width: 53px;
        height: 53px;
        margin-right: 20px;
      }
      .txt {
        background-color: #F6F7FB;
        border-radius: 2rpx;
        padding: 20px 24px;
      }
    }
    .tirow_r {
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
      .dui {
        position: absolute;
        top: -14px;
        right: 0;
        width: 22px;
        height: 22px;
      }
      .txt {
        background-color: #fff;
        border-radius: 20px;
        padding: 20px 24px;
        color: #00D315;
        border: 1px solid #00D315;
      }
    }
    .err {
      .txt {
        color: #FF0000!important;
        border: 1px solid #FF0000!important;
      }
    }
  }
</style>
