<template>
   <div class="comMain">
      <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="confirm"  v-if="!detail"></count-down>
      <!-- 加入隐藏的audio播放器 -->
      <!-- <audio :src="questionDetail.audio" ref="audio"
            @ended="end"
            @play="playMusic()"
            @pause="playMusic()"
            :autoplay="isauto"
            controls
            style="display: none"
            preload="metadata">
          您的浏览器不支持音频播放
      </audio> -->
      <div class="content">
        <div class="playcon" @click="playMusic()"> 
          <img src="../assets/image/audio.png" alt="" v-if="!curPlay" >
          <img src="../assets/image/audio2.png" alt="" v-else>
          <div class="nums">
            <span class="font-30 text-bold">{{play_number}}</span>
            <span class="font-14">剩余播放次数</span>
          </div>
        </div>
         <div class="huida">
          <div class="font-32 text-bold text-center m-b-30">{{questionDetail.name}}</div>
          <el-input type="textarea" v-model="answer" v-if="!detail"></el-input>
          <el-input type="textarea" v-model="answer" :disabled="true" v-else></el-input>
        </div>
      </div>
   </div>
</template>
<script>
import playAudio from '@/util/playAudio.js'
export default {
    props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      },
    },
   data() {
      return {
        answer: '',
        //播放次数
        play_number: 0,
      }
   },
   mixins: [playAudio],
   mounted() {
    this.play_number = this.questionDetail.play_number;
   },
   methods: {
      // 播放方法
      playMusic() {
        //播放次数-减一
        if(this.play_number == 0) return
        if(!this.curPlay)  {   //判断不是暂停的情况下减一
          if(this.play_number>0) this.play_number--;
        }
        this.playmp3('',3,this.questionDetail);
      },
      confirm() {
        if(!this.answer) {
          this.$message({
            message: "请输入答案",
            type: 'warning'
          })
          return;
        }

         // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)

        this.$emit("submitAnswer",{
          answer: this.answer,
          supletime: supletime
        })
        this.answer = "";
      },
   }
}
</script>
<style  lang="scss" scoped>
.content {
  display: flex;
  margin: 40px 20px;
  align-items: end;
  justify-content: center;
}
.huida {
    width: 500px;
    ::v-deep .el-textarea__inner {
      border: 1px solid #BBBEC9;
      height: 183px;
      border-radius: 2px;
      padding: 10px;
      box-sizing: border-box;
    } 
  }

.playcon {
  display: flex;
  align-items: center;
  padding: 0 0 0 40px;
  width: 348px;
  height: 183px;
  background: #F6F7FB;
  border-radius: 2px;
  box-sizing: border-box;
  margin-right: 30px;
  img {
    width: 105px;
    height: 105px;
    margin-right: 60px;
  }
  .nums {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
