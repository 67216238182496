<template>
   <div class="comMain">
      <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="editWrite" :subtitle="title" v-if="!detail"></count-down>
      <div class="content">
        <div class="m-b-40 text-bold font-22">{{questionDetail.name}}</div>
        <div class="ti">
          {{questionDetail.sub_name}}
        </div>
      </div>
      <!-- 小题 -->
      <block v-if="!detail">
        <block v-for="(item,index) in questionDetail.problem" :key="index" v-if="is_edit">
          <div class="content" v-if="index<=problem_index">
            <div class="m-b-40 text-bold font-22">{{index+1}}.{{item.title}}</div>
            <div class="font-20 m-b-30">
              <div v-html="item.name"></div>
            </div>
            <el-input type="textarea" v-model="item.content" placeholder="Your response" ></el-input>
          </div>
        </block>
      </block>
      <block v-else>
        <block v-for="(item,index) in questionDetail.problem" :key="index">
          <div class="content">
            <div class="m-b-40 text-bold font-22">{{index+1}}.{{item.title}}</div>
            <div class="font-20 m-b-30">
              <div v-html="item.name"></div>
            </div>
            <el-input type="textarea" v-model="item.answer" placeholder="Your response" :disabled="true"></el-input>
          </div>
        </block>
      </block>
   </div>
</template>
<script>
export default {
  props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
   data() {
      return {
        answer: '',
        title: "开始答题", //下一小题   提交答案
        is_edit: false,   //是否编辑
        problem_index: 0,  //小题索引
        is_first: false, //是否第二次点击下一小题
      }
   },
   methods: {
      editWrite() {
        this.is_edit = true;
        if(this.title == "提交答案") {
          this.nextSubmit()
        }
        if(parseInt(this.problem_index+1) == this.questionDetail.problem.length) {
          this.title = "提交答案";
        }else if(parseInt(this.problem_index+1) < this.questionDetail.problem.length){
          this.title = "下一小题";
          if(this.is_first) this.problem_index++;
          this.is_first = true;
          if(parseInt(this.problem_index+1) == this.questionDetail.problem.length) this.title = "提交答案";
        }
      },
      nextSubmit() {
        //获取填写的答案
        var answer = [];
        
        for(var i=0;i<this.questionDetail.problem.length;i++) {
          if(!this.questionDetail.problem[i].content) {
            this.$message({
              message: '请输入答案',
              type: 'warning'
            });
            return
          }else {
            answer.push(this.questionDetail.problem[i].content);
          }
        }

        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)
       
        this.$emit("submitAnswer",{
          answer: answer,
          supletime: supletime
        })
      },
    }
}
</script>
<style  lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    margin: 40px auto;
    width: 500px;
    .ti {
      border: 1px solid #BBBEC9;
      padding: 30px;
      border-radius: 4px;
    }
    ::v-deep .el-textarea__inner {
      border: 1px solid #BBBEC9;
      width: 500px;
      height: 183px;
      border-radius: 2px;
      padding: 10px;
      box-sizing: border-box;
    } 
  }
</style>
