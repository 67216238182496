<template>
   <div class="comMain">
      <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="confirm" :subtitle="title" v-if="!detail"></count-down>
      <!-- 第一种题型 -->
      <!-- <div class="content" v-if="questionDetail.type==0">
        <div class="font-36 m-b-40 text-bold">{{questionDetail.name}}</div>
        <div class="desc" v-if="questionDetail.location==-1">
          <block v-if="!detail">
            <div class="select" @click="show_select = !show_select">
              <span class="ellipsis">{{answer}}</span>
              <img src="../assets/image/down2.png" alt="" class="down2">
              <div class="optionsPanel" v-if="show_select">
                <div class="option ellipsis" v-for="(item,index) in questionDetail.options" :key="index" @click="selectOption(item)">{{item.translate}}</div>
              </div>
            </div>
          </block>
          <block v-else>
            <view class="select">
              <span class="ellipsis">{{questionDetail.user_answer}}</span>
              <img src="../assets/image/down2.png" alt="" class="down2">
            </view>
          </block>
          <span class="">
            {{questionDetail.front_content}}{{questionDetail.later_content}}
          </span>
        </div>
        <div class="desc" v-if="questionDetail.location==0">
          <span class="indent">
            {{questionDetail.front_content}}
            </span>
            <block v-if="!detail">
              <div class="select" @click="show_select = !show_select">
                <span class="ellipsis">{{answer}}</span>
                <img src="../assets/image/down2.png" alt="" class="down2">
                <div class="optionsPanel" v-if="show_select">
                  <div class="option ellipsis" v-for="(item,index) in questionDetail.options" :key="index" @click="selectOption(item)">{{item.translate}}</div>
                </div>
              </div>
            </block>
            <block v-else>
              <div class="select">
                <span class="ellipsis">{{questionDetail.user_answer}}</span>
                <img src="../assets/image/down2.png" alt="" class="down2">
              </div>
            </block>
            <span>
            {{questionDetail.later_content}}
            </span>
        </div>
        <div class="desc" v-if="questionDetail.location==1">
            <span class="indent">
            {{questionDetail.front_content}}{{questionDetail.later_content}}
            </span>
            <block v-if="!detail">
              <div class="select" @click="show_select = !show_select">
                <span class="ellipsis">{{answer}}</span>
                <img src="../assets/image/down2.png" alt="" class="down2">
                <div class="optionsPanel" v-if="show_select">
                  <div class="option ellipsis" v-for="(item,index) in questionDetail.options" :key="index" @click="selectOption(item)">{{item.translate}}</div>
                </div>
              </div>
            </block>
            <block v-else>
              <div class="select">
                <span class="ellipsis">{{questionDetail.user_answer}}</span>
                <img src="../assets/image/down2.png" alt="" class="down2">
              </div>
            </block>
        </div>
      </div> -->
      <!-- 第二种题型 -->
      <!-- <div class="content" v-if="questionDetail.type==1">
        <div class="font-22 m-b-40 text-bold">{{questionDetail.name}}</div>
        <div class="font-20 m-b-20">
          {{questionDetail.sub_name}}
        </div>
        <el-input type="textarea" v-model="answer" placeholder="我的答案" v-if="!detail"></el-input>
        <el-input type="textarea" v-model="questionDetail.user_answer" :disabled="true" placeholder="我的答案" v-else></el-input>
      </div> -->

    <!-- 答题后 -->
    <div class="content" v-if="detail">
      <div class="titlelist flex-center">
        <div :class="currentindex==index?'option':''" v-for="(item,index) in questionDetail.son_list" @click="changeCurrent(index)">{{index+1}}</div>
      </div>
      <div class="flex-y-baseline m-b-20 m-t-20" style="justify-content: center;">答题正确率 <span class="c-origin text-bold m-l-10 m-r-10">{{questionDetail.son_list[currentindex].correct}}</span> </div>
    </div>

    <block v-for="(item,index) in son_list" :key="index" v-if="index == currentindex">
     <!-- 第一种 单选多空 -->
      <div class="content flex-center2 content2" v-if="item.type == 'more'">
        <div class="font-32 text-bold m-b-20 text-center">{{item.sub_name}}</div>
        <div class="desc">
          <block v-for="(optionitem,optionindex) in item.splitname" :key="optionindex">
            <block v-if="optionitem">
              <span class="m-r-10" :class="optionindex==0?'indent':''">
                  {{optionitem}}
              </span>
              <!-- 未答题 -->
              <div class="select small" v-if="optionindex<item.more_options.length&&!detail" @click="openCheck(optionindex)">
                <span class="ellipsis">{{item.more_options[optionindex].content}}</span>
                <img src="../assets/image/down2.png" alt="" class="rightimg" />
                <div class="optionsPanel" v-if="checkindex == optionindex">
                  <div class="option2 ellipsis" v-for="(m,n) in item.more_options[optionindex].options" :key="n" @click.stop="chooseCheck(optionindex,index,m)">{{m.options}}</div>
                </div>
              </div>
              <!-- 答题后 -->
              <div class="select small" v-if="optionindex<item.more_options.length&&detail">
                <span class="ellipsis origin">{{item.user_answer[optionindex].user_answer_detail}}</span>
                <img src="../assets/image/down2.png" alt="" class="rightimg">
                <div class="optionsPanel" v-if="checkindex == optionindex">
                  <div class="option2 ellipsis" v-for="(m,n) in item.more_options[optionindex].options" :key="n" @click.stop="chooseCheck(optionindex,index,m)">{{m.options}}</div>
                </div>
              </div>
              <span class="green m-l-4 m-r-4" v-if="optionindex<item.more_options.length&&detail">{{item.user_answer[optionindex].user_answer_detail}}</span>
            </block>
          </block>
        </div>
      </div>
      
      <!-- 第二种 单空单选 1句子中间选择 -->
      <div class="content flex-center2" v-if="item.type == 'one'&&item.category==0">
        <div class="font-32 text-bold m-b-20 text-center">{{item.sub_name}}</div>
        <div class="desc">
          <block v-for="(optionitem,optionindex) in item.splitname" :key="optionindex">
            <block v-if="optionitem">
              <span class="m-r-10" :class="optionindex==0?'indent':''">
                  {{optionitem}}
              </span>
              <!-- 未答题 -->
              <div class="select" v-if="optionindex<1&&!detail" @click="openCheck(optionindex)">
                <span class="ellipsis">{{item.content}}</span>
                <img src="../assets/image/down2.png" alt="" class="rightimg">
                <div class="optionsPanel" v-if="checkindex == optionindex" style="width: 600px;">
                  <div class="option2 ellipsis" v-for="(m,n) in item.options" :key="n" @click.stop="chooseOneCheck(index,m)">{{m.options}}</div>
                </div>
              </div>
              <!-- 答题后 -->
              <div class="select" v-if="optionindex<1&&detail">
                <span class="ellipsis origin">{{son_list[index].user_answer[optionindex].user_answer_detail}}</span>
                <img src="../assets/image/down2.png" alt="" class="rightimg">
                <div class="optionsPanel" v-if="checkindex == optionindex" style="width: 600rpx;">
                  <div class="option2 ellipsis" v-for="(m,n) in item.options" :key="n" @click.stop="chooseOneCheck(index,m)">{{m.options}}</div>
                </div>
              </div>
              <span class="green m-l-4 m-r-4" v-if="optionindex<1&&detail">{{son_list[index].user_answer[optionindex].user_answer_detail}}</span>
            </block>
          </block>
        </div>
      </div>
      
      <!-- 第二种 单空单选 2根据一段话，单选内容 -->
      <div class="content" v-if="item.type == 'one'&&item.category==1">
        <div class="text-bold font-20 m-b-10 text-center">{{item.sub_name}}</div>
        <div class="m-b-20 font-18">
          {{item.name}}
        </div>
        <div class="font-18 m-b-10">Select the best response.</div>
        <!-- 未答题 -->
        <block v-if="!detail">
          <div class="options" :class="m.checked?'active':''" v-for="(m,n) in item.options" :key="n" @click="chooseRadio(index,m,n)">
            <img src="../assets/image/radio2.png" alt="" v-if="m.checked">
            <img src="../assets/image/radio.png" alt=""  v-else>
            <div class="flex-1">{{m.options}} </div>
          </div>
        </block>
        <!-- 答题后 -->
        <block v-if="detail">
          <div class="options" :class="m.name==son_list[index].user_answer[0].answer?'active':''" v-for="(m,n) in item.options" :key="n">
             <img src="../assets/image/radio2.png" alt="" v-if="m.name==son_list[index].user_answer[0].answer">
            <img src="../assets/image/radio.png" alt=""  v-else>
            <div class="flex-1">{{m.options}} </div>
          </div>
          <span class="green m-l-4 m-r-4" v-if="detail">{{son_list[index].user_answer[0].user_answer_detail}}</span>
        </block>
      </div>
      
      <!-- 第三种 复制粘贴类型 -->
      <div class="content" v-if="item.type == 'mark'">
        <div class="font-20 m-b-40 text-bold text-center">{{item.sub_name}}</div>
        <div class="font-18 m-b-20">
          <span>{{item.name}}</span>
        </div>
        <!-- 未答题 -->
        <el-input type="textarea" v-model="item.content" placeholder="长按粘贴答案" v-if="!detail" @input="inputValue($event,index,item)"></el-input>
        
        <!-- 答题后 -->
        <div class="origin" v-if="detail">
          {{son_list[index].user_answer[0].user_answer_detail}}
        </div>
      </div>
      
    </block>



     
   </div>
</template>
<script>
export default {
  props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
   data() {
      return {
        answer: '',
        show_select: false, //是否显示选项
        currentindex: 0,
        title: '下一小题',
        checkindex: -1,
        son_list: [],
        is_submit: false, //是否提交答案
      }
   },
   mounted() {
      this.calcData()
    },
   methods: {
    inputValue(e,index,item) {
      // console.log("888",e,index,item)
      this.$set(this.son_list,index,item)
    },
    calcData() {
        // console.log('5555555')
        if(this.questionDetail.topic_type_name == "互动阅读") {
          
          this.questionDetail.son_list.forEach((v,k) => {
            if(v.type == 'more' || (v.type == 'one'&&v.category == 0)) {
              v.splitname = v.name.split("[]");
            }
            if(v.type == 'more') {
              v.more_options.forEach((m,n) => {
                m.content = "";
                m.checkanswer = "";
                m.options.forEach((i,k) => {
                  i.checked = false;
                })
              })
            }
            if(v.type == 'one') {
              v.content = "";
              v.checkanswer = "";
              v.options.forEach((m,n) => {
                  m.checked = false;
              })
            }
            if(v.type == 'mark') {
              v.content = "";
              
            }
          }) 
          
          this.son_list = this.questionDetail.son_list;
          console.log("son_list",this.son_list)
          
          //判断是否有多到题型
          if(this.questionDetail.son_list.length>1) this.title = "下一小题";
          else this.title = "提交答案";
          
          this.currentindex = 0;
          this.checkindex = -1;
          
          this.$forceUpdate()
        }
       
      },
      changeCurrent(index) {
        this.currentindex = index;
      },
      selectOption(item) {
        this.answer = item.translate;
      },
      openCheck(childindex) {
        this.checkindex = childindex;
      },
      chooseCheck(childindex,parentindex,item) {
        this.son_list[parentindex].more_options[childindex].content = item.options;
        this.son_list[parentindex].more_options[childindex].checkanswer = item.name;
        this.$forceUpdate()
        //初始化索引
        this.checkindex = -1;
      },
      //单提单选
      chooseOneCheck(parentindex,item) {
        this.son_list[parentindex].content = item.options;
        this.son_list[parentindex].checkanswer = item.name;
        this.$forceUpdate()
        //初始化索引
        this.checkindex = -1;
      },
      chooseRadio(parentindex,item,index) {
        this.son_list[parentindex].content = item.options;
        this.son_list[parentindex].checkanswer = item.name;
        this.son_list[parentindex].options.forEach((v,k) => {
          if(index == k) v.checked = true;
          else v.checked = false;
        })
        this.$forceUpdate()
      },
      selectOption(item) {
        this.answer = item.translate;
      },
      // confirm() {
      //   if(!this.answer) {
      //     this.$message({
      //         message: '请输入答案',
      //         type: 'warning'
      //       });
      //     return;
      //   }

      //   // 获取答题所用时间(s)
      //   var remain_time = this.$refs.countDownComponent.remain_time;
      //   var total_time = this.questionDetail.audio_time;
      //   var supletime = total_time - remain_time;
      //   // console.log(supletime)

      //   this.$emit("submitAnswer",{
      //     answer: this.answer,
      //     supletime: supletime
      //   })
      // },
      confirm() {
       
          //先判断题型  是否填写答案   未填写   不通过
          if(this.son_list[this.currentindex].type == 'more') {
            for(var i=0;i<this.son_list[this.currentindex].more_options.length;i++) {
              if(!this.son_list[this.currentindex].more_options[i].content) {
                this.$message({
                  message: '请选择答案',
                  type: 'warning'
                });
                return
              }
            }
          }else if(this.son_list[this.currentindex].type == 'one') {
            if(!this.son_list[this.currentindex].content) {
               this.$message({
                  message: '请选择答案',
                  type: 'warning'
                });
              return
            }
          }else {
            if(!this.son_list[this.currentindex].content) {
              this.$message({
                  message: '请选择答案',
                  type: 'warning'
                });
              return
            }
          }
          
          if(this.title == "提交答案") {
            this.nextSubmit()
          }
          if(parseInt(this.currentindex+1) == this.questionDetail.son_list.length) {
            this.title = "提交答案";
          }else if(parseInt(this.currentindex+1) < this.questionDetail.son_list.length){
            this.title = "下一小题";
            this.currentindex++;
            if(parseInt(this.currentindex+1) == this.questionDetail.son_list.length) this.title = "提交答案";
          }
        
      },
      nextSubmit() {
       
        //获取填写的答案
        var answer = [];
        
        this.son_list.forEach((v,k) => {
          if(v.type == 'more') {
            var chooseStr = [];
            v.more_options.forEach((m,n) => {
              chooseStr.push(m.checkanswer);
            })
            answer.push({
              son_id: v.id,
              answer: chooseStr.join(',')
            })
          }else if(v.type == 'one') {
            answer.push({
              son_id: v.id,
              answer: v.checkanswer
            })
          }else {
            answer.push({
              son_id: v.id,
              answer: v.content
            })
          }
        })
       

        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)

        this.$emit("submitAnswer",{
          answer: answer,
          supletime: supletime
        })
        
      },
    }
}
</script>
<style  lang="scss" scoped>
.flex-center2 {
  display: flex;
  flex-direction: column;
}
.content2 {
  min-height: 600px;
}
.content {
    padding: 0 30px;
    margin: 40px auto;
    width: 600px;
    .desc {
      line-height: 3;
      font-size: 18px;

      display: inline-block;
      white-space: pre-wrap;  // 规定段落中的文本不进行换行
      word-break: break-all;  // 允许单词中换行，在容器的最右边进行断开不会浪费控件
      word-wrap: break-word;  // 防止长单词溢出，单词内部短句
      .indent {
        text-indent: 2rem;
      }
      .select {
        display: inline-block;
        position: relative;
        width: 600px;
        height: 38px;
        line-height: 38px;
        text-align: right;
        border: 1px solid #8D93A5;
        border-radius: 2px;
        font-size: 20px;
        margin-right: 4px;
        padding: 0 10px;
        box-sizing: border-box;
        span {
          display: inline-block;
          width: 540px;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          float: left;
          font-size: 18px;
        }
      }
      .small {
        width: 114px!important;
        span {
          width: 80px!important;
        }
      }
      .rightimg {
        width: 7px;
        height: 4px;
        vertical-align: middle;
      }
    }
    ::v-deep .el-textarea__inner {
      border: 1px solid #BBBEC9;
      height: 183px;
      border-radius: 2px;
      padding: 10px;
      box-sizing: border-box;
    } 
  }
  
  // 筛选面板
  .optionsPanel {
    position: absolute;
    // bottom: -70rpx;
    left: 0;
    background-color: #fff;
    border-radius: 2px;
    width: 112px;
    box-shadow: 0 0 4px 4px #0000000d;
    border: 1px solid #DDDFE4;
    z-index: 999;
    .option2 {
      color: #5D6581;
      height: 38px;
      line-height: 38px;
      text-align: center;
      padding: 0 30px;
      border-bottom: 1px solid #DDDFE4;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  
  
  // 新增
  .titlelist {
    div {
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border: 1px solid #BBBEC9;
      border-radius: 10px;
      font-size: 20px;
      color: #8D93A5;
      margin-right: 30px;
    }
    .option {
      border: 1px solid $color-title2!important;
      color: $color-title2!important;
    }
  }
  
  .options {
    display: flex;
    align-items: center;
    background-color: #F6F7FB;
    border-radius: 2px;
    padding: 20px 30px;
    margin-bottom: 20px;
    box-sizing: border-box;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .active {
    color: $color-title2;
    border: 1px solid $color-title2;
    background-color: #fff!important;
    view {
      color: $color-title2;
    }
  }
  
  .green {
    color: #00D315;
    font-size: 18px;
  }
  .origin {
    color: #FF0000!important;
  }
</style>
