<template>
   <div class="comMain">
    <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="confirm"  v-if="!detail"></count-down>
       <div class="content">
        <div class="font-22 m-b-40 text-bold">{{questionDetail.name}}</div>
        
        <!-- <div class="desc" v-if="questionDetail.location==-1">
          <block v-for="(item,index) in kong_value_list" :key="index">
            <block v-if="!detail">
              <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
              <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="item.look" v-else>
            </block>
            <block v-else>
              <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
              <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="true" v-else>
            </block>
          </block>
          <span class="m-l-10">
            {{questionDetail.front_content}}{{questionDetail.later_content}}
          </span>
        </div>
        
        <div class="desc" v-if="questionDetail.location==0">
          <span class="indent m-r-10">
            {{questionDetail.front_content}}
            </span>
            <block v-for="(item,index) in kong_value_list" :key="index">
              <block v-if="!detail">
                <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
                <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="item.look" v-else>
              </block>
              <block v-else>
                <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
                <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="true" v-else>
              </block>
            </block>
          <span class="m-l-10">
            {{questionDetail.later_content}}
            </span>
        </div>
       
        <div class="desc" v-if="questionDetail.location==1">
          <span class="indent m-r-10">
              {{questionDetail.front_content}}{{questionDetail.later_content}}
          </span>
          <block v-for="(item,index) in kong_value_list" :key="index">
            <block v-if="!detail">
              <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
              <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="item.look" v-else>
            </block>
            <block v-else>
              <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
              <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="true" v-else>
            </block>
          </block>
        </div> -->

        <!-- 新加修改部分 -->
      <div class="desc">
        <block v-for="(item,index) in new_sentence" :key="index">
          <block v-if="item">
            <span class="m-r-10" :class="index==0?'indent':''">
                {{item}}
            </span>
            <!-- 未答题 -->
           <block v-for="(m,n) in kong_value_list" :key="n" v-if="n==index">
           <block v-for="(worditem,wordindex) in m.answer" :key="wordindex" >
             <block v-if="!detail">
               <input type="text" class="kong" :class="worditem.look?'dis':''" v-model="worditem.answer"  :disabled="worditem.look" v-if="worditem.look">
               <input type="text" class="kong" :class="worditem.look?'dis':''" v-model="worditem.content" :disabled="worditem.look" maxlength="1"  @input="inputKong($event,index,wordindex)" :focus="worditem.focus" v-else>
             </block>
              <block v-else>
                <input type="text" class="kong" :class="worditem.look?'dis':''" v-model="worditem.answer"  :disabled="true" v-if="worditem.look">
                <input type="text" class="kong" :class="worditem.is_true?'dui':'err'" v-model="worditem.write" :disabled="true" maxlength="1" v-else>
              </block>
            </block>
            </block>
          </block>
          <block v-else>
            <!-- 未答题 -->
            <block v-for="(m,n) in kong_value_list" :key="n" v-if="n==index">
            <block v-for="(worditem,wordindex) in m.answer" :key="wordindex">
               <block v-if="!detail">
                 <input type="text" class="kong" :class="worditem.look?'dis':''" v-model="worditem.answer"  :disabled="worditem.look" v-if="worditem.look">
                 <input type="text" class="kong" :class="worditem.look?'dis':''" v-model="worditem.content" :disabled="worditem.look" maxlength="1"  @input="inputKong($event,index,wordindex)" :focus="worditem.focus" v-else>
               </block>
                <block v-else>
                  <input type="text" class="kong" :class="worditem.look?'dis':''" v-model="worditem.answer"  :disabled="true" v-if="worditem.look">
                  <input type="text" class="kong" :class="worditem.is_true?'dui':'err'" v-model="worditem.write" :disabled="true" maxlength="1" v-else>
                </block>
             </block>
          </block>
          </block>
        </block>
      </div>

    </div>
     
   </div>
</template>
<script>
export default {
  props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
   data() {
      return {
        kong_value_list: [],
        new_sentence: []
      }
   },
   mounted() {
      this.calcData()
    },
    methods: {
      calcData() {
        if(this.questionDetail.topic_type_name == "阅读填空") {
          this.kong_value_list = [];
          if(!this.detail) {
            
            // 第一步拆分
            var str = this.questionDetail.sentence.split("[]");
            
            
            this.questionDetail.sentence_config.forEach((v,k) => {
              v.answer.forEach((m,n) => {
                m.answer = m.answer;
                m.look = m.look;
                m.content = m.look?m.answer:'';
                m.correct = false;
                m.focus = false;
              })
            })
            this.kong_value_list = this.questionDetail.sentence_config;
            this.new_sentence = str;
            
          }else {
            var str = this.questionDetail.sentence.split("[]");
            
            
            this.kong_value_list = this.questionDetail.user_answer;
            this.new_sentence = str;
          }
          console.log("kong_value_list",this.kong_value_list)
        }
      },
      //输入框输入事件
      inputKong(e,parentindex,index) {
        return
        console.log("输入事件",e,parentindex,index)
        //更改输入框光标位置、限制输入框输入字数
        if(e.detail.value) {
          if(parentindex+1==this.kong_value_list.length&&index+1 == this.kong_value_list[parentindex].answer.length ) return
          if(parentindex+1<this.kong_value_list.length) {
            if(index+1 == this.kong_value_list[parentindex].answer.length) {
              // console.log("8888")
              // console.log(this.kong_value_list[parentindex+1].answer)
              var is_first = true; //第一个空
              this.kong_value_list[parentindex+1].answer.forEach((v,k) => {
                if(v.look==false&&is_first==true) {
                  v.focus = true;
                  is_first = false;
                }
              })
            }else {
              this.kong_value_list[parentindex].answer[index+1].focus = true;
            }
          }else {
            this.kong_value_list[parentindex].answer[index+1].focus = true;
          }
        }
        this.$forceUpdate()
        // console.log("数组",this.kong_value_list)
      },
      confirm() {
        //获取填写的答案
        var answer = [];

        for(var i=0;i<this.kong_value_list.length;i++) {
          var arr = [];
          for(var j=0;j<this.kong_value_list[i].answer.length;j++) {
            if(!this.kong_value_list[i].answer[j].content) {
              this.$message({
                message: '请输入答案',
                type: 'warning'
              });
              return
            }else {
              arr.push(this.kong_value_list[i].answer[j].content);
            }
          }
          answer.push(arr);
        }

        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)
        
        this.$emit("submitAnswer",{
          answer: answer,
          supletime: supletime
        })
      },
    }
}
</script>
<style  lang="scss" scoped>
 .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    margin: 40px auto;
    .desc {
      line-height: 3;
      font-size: 18px;

      display: inline-block;
      white-space: pre-wrap;  // 规定段落中的文本不进行换行
      word-break: break-all;  // 允许单词中换行，在容器的最右边进行断开不会浪费控件
      word-wrap: break-word;  // 防止长单词溢出，单词内部短句
      .indent {
        text-indent: 2rem;
      }
      .kong {
        display: inline-block;
        width: 35px;
        height: 40px;
        line-height: 40rpx;
        text-align: center;
        border: 1px solid #8D93A5;
        border-radius: 2px;
        font-size: 20px;
        margin-right: 4px;
      }
      .dis {
        background-color: #BBBEC9!important;
        color: #fff!important;
        border: none;
      }
      .err {
        border: 1px solid #FF0000!important;
        color: #FF0000!important;
      }
      .dui {
        border: 1px solid #00D315!important;
        color: #00D315!important;
      }
      .green {
        color: #00D315;
        margin-right: 10px;
      }
    }
  }
</style>
