<template>
   <div class="comMain">
      <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="openLuyin"  :subtitle="title" v-if="!detail"></count-down>
      <div class="content">
        <div class="font-22 m-b-40 text-bold">{{questionDetail.name}}</div>
        <div class="playcon" @click="playMusic()" v-if="!detail"> 
          <img src="../assets/image/audio.png" alt="" v-if="!curPlay">
          <img src="../assets/image/audio2.png" alt="" v-else>
          <div class="nums">
            <span class="font-30 text-bold">{{play_number}}</span>
            <span class="font-14">剩余播放次数</span>
          </div>
        </div>
      </div>
      <!-- 答题后 -->
      <div class="daan" v-if="detail">
        <div class="font-22 text-bold m-b-20">我的答案</div>
        <audio :src="questionDetail.user_answer" ref="audio"
           :autoplay="false"
           controls
           controlslist="nodownload  noremoteplayback noplaybackrate" 
           preload="metadata">
          您的浏览器不支持音频播放
        </audio>
      </div>

      <!-- 录制音频 -->
      <make-audio :show="show" :time="questionDetail.audio_time" @close="closeAudio" @confirm="submitAudio" :key="timer" ref="makeAudio" v-if="show"></make-audio>

   </div>
</template>
<script>
import playAudio from '@/util/playAudio.js'
export default {
  props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
   data() {
      return {
        answer: '',
        title: "录制答案",
        play_number: 0,  //播放剩余次数
        //录音部分
        show: false, 
        timer: '',
      }
   },
   mixins: [playAudio],
    mounted() {
      this.play_number = this.questionDetail.play_number;
    },
    methods: {
      // 播放方法
      playMusic() {
        //播放次数-减一
        if(this.play_number == 0) return
        if(!this.curPlay)  {   //判断不是暂停的情况下减一
          if(this.play_number>0) this.play_number--;
        }
        var items = {
          audio: this.questionDetail.sub_name_audio
        };
        this.playmp3('',3,items);
      },
      openLuyin() {
        this.show = true;
        this.timer = new Date().getTime();
      },
      closeAudio() {
        this.show = false;
      },
      submitAudio(e) {
        this.answer = e.voiceUrl;
        this.show = false;

        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)

        this.$emit("submitAnswer",{
          answer: this.answer,
          supletime: supletime
        })
      },
    }
}
</script>
<style  lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    margin: 40px auto;
  }

  .playcon {
    display: flex;
    align-items: center;
    padding: 0 0 0 40px;
    width: 348px;
    height: 183px;
    background: #F6F7FB;
    border-radius: 2px;
    box-sizing: border-box;
    margin-right: 30px;
    img {
      width: 105px;
      height: 105px;
      margin-right: 60px;
    }
    .nums {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  // 我的答案
  .daan {
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
